import React, { useMemo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Pagination } from '@material-ui/lab';
import { theme } from '../../theme';
import Layout from '../../components/Layout';
import { darken, lighten } from 'polished';
import Text from '../../reusecore/src/elements/Text/text';
import Box from '../../reusecore/src/elements/Box';
import { graphql, useStaticQuery } from 'gatsby';
import { MatchesQuery } from '../../graphql-types';
import { head } from 'lodash-es';
import { MatchTherapistCard } from '../../components/TherapistCard';
import Select from 'react-select';
import {
  Ethnicities,
  Genders,
  pricingBands,
  Sexualities,
  Specialisms,
  TherapyTypes,
} from '../../utils/match';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { MatchResultsFirebase } from './MatchAuth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth, getFirebaseFirestore } from '../../services/firebase';
import Input from '../../components/Input';

export interface DirectoryAuthProps {}

interface TherapistScoreMap {}

const OTHER_VALUE = 'Other';

const DirectoryAuth: React.FunctionComponent<DirectoryAuthProps> = (): JSX.Element => {
  const {
    allContentfulTherapist: { edges: therapistData },
  } = useStaticQuery<MatchesQuery>(graphql`
    query Directory {
      allContentfulTherapist(filter: { currentlyAvailable: { eq: true } }) {
        edges {
          node {
            slug
            therapyTypes
            specialisms
            dateOfBirth
            sexuality
            gender
            ethnicity
            sessionPricing
            profilePhoto {
              fixed(width: 600, height: 400) {
                src
              }
            }
            fullName
            headline {
              headline
            }
            title
          }
        }
      }
    }
  `);

  // @TODO: Note that this should always be not loading here because of the gate above.
  const [user, loading, error] = useAuthState(getFirebaseAuth());

  const db = getFirebaseFirestore();

  const [matchesSnapshot, matchesLoading, matchesError] = useCollectionDataOnce<
    MatchResultsFirebase
  >(
    !db
      ? null
      : db
          .collection('users')
          .doc(user.uid)
          .collection('matchResults')
          .orderBy('creationDate', 'desc')
  );

  const [page, setPage] = useState<number>(1);

  const [therapy, setTherapy] = useState<string>(null);
  const [specialism, setSpecialism] = useState<string>(null);
  const [gender, setGender] = useState<string>(null);
  const [sexuality, setSexuality] = useState<string>(null);
  const [ethnicity, setEthnicity] = useState<string>(null);
  const [maxPrice, setMaxPrice] = useState<number>(null);
  const [therapistName, setTherapistName] = useState<string>('');

  const therapistsToShowThunk = useMemo<(TherapistScoreMap) => JSX.Element[]>(
    () => (scoreMap: TherapistScoreMap) =>
      therapistData
        .filter(
          therapy && therapy !== OTHER_VALUE
            ? x =>
                !!x.node.therapyTypes && x.node.therapyTypes.includes(therapy)
            : x => true
        )
        .filter(
          specialism && specialism !== OTHER_VALUE
            ? x =>
                !!x.node.specialisms && x.node.specialisms.includes(specialism)
            : x => true
        )
        .filter(gender ? x => x.node.gender === gender : x => true)
        .filter(sexuality ? x => x.node.sexuality === sexuality : x => true)
        .filter(ethnicity ? x => x.node.ethnicity === ethnicity : x => true)
        .filter(maxPrice ? x => x.node.sessionPricing <= maxPrice : x => true)
        .filter(
          therapistName
            ? x =>
                x.node.fullName
                  .toLowerCase()
                  .includes(therapistName.toLowerCase())
            : x => true
        )
        .map<JSX.Element>(x => (
          <Col
            key={x.node.slug}
            xs={12}
            sm={4}
            style={{ display: 'flex', paddingBottom: theme.space[4] }}
          >
            <MatchTherapistCard
              title={x.node.title}
              fullName={x.node.fullName}
              pricePerSession={x.node.sessionPricing}
              slug={x.node.slug}
              headline={x.node.headline.headline}
              profileImageSrc={x.node.profilePhoto.fixed.src}
              score={scoreMap[x.node.slug]}
              directory
            />
          </Col>
        )),
    [
      therapistData,
      therapy,
      maxPrice,
      sexuality,
      specialism,
      ethnicity,
      gender,
      therapistName,
    ]
  );

  if (matchesLoading) {
    return (
      <Layout contrast>
        <Box
          flexBox
          justifyContent="center"
          alignItems="center"
          bg="sand"
          width="100%"
          height="calc(100vh - 70px)"
        >
          <div className="sk-bounce">
            <div
              className="sk-bounce-dot"
              style={{ backgroundColor: 'slategray' }}
            />
            <div
              className="sk-bounce-dot"
              style={{ backgroundColor: 'slategray' }}
            />
          </div>
        </Box>
      </Layout>
    );
  }

  const scoreMap: TherapistScoreMap = !!matchesSnapshot.length
    ? head<MatchResultsFirebase>(matchesSnapshot).resultsObject.reduce((a, b) =>
        Object.assign({}, a, { [b.therapistId]: b.score })
      )
    : {};

  const therapistsToShow = therapistsToShowThunk(scoreMap);

  return (
    <Layout contrast>
      <Container
        style={{
          backgroundImage: `linear-gradient(25deg, ${darken(
            0.15,
            theme.colors.secondary
          )}, ${lighten(0.1, theme.colors.secondary)})`,
        }}
        fluid
      >
        <Container>
          <Row>
            <Col
              className="d-block d-md-flex"
              style={{
                minHeight: theme.sizes[8],
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 0',
              }}
            >
              <Box flexBox flexDirection="column" width="100%">
                <Text
                  lineHeight="copy"
                  m={0}
                  pb={3}
                  fontFamily="heading"
                  fontWeight={3}
                  fontSize={2}
                  color={theme.colors.white}
                >
                  Therapist Directory
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  letterSpacing="tracked"
                  m={0}
                  pb={1}
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Filter
                </Text>
                <Box flexBox flexDirection={['column', 'row']}>
                  <Select
                    styles={{
                      container: base => ({
                        ...base,
                        flex: 1,
                        marginRight: theme.space[2],
                        marginBottom: theme.space[2],
                      }),
                    }}
                    options={[
                      ...Object.values(TherapyTypes).map(x => ({
                        value: x,
                        label: x,
                      })),
                      { value: OTHER_VALUE, label: OTHER_VALUE },
                    ]}
                    placeholder="Therapy"
                    isSearchable={false}
                    onChange={input => {
                      // @ts-ignore
                      setTherapy(input.value);
                    }}
                    value={therapy ? { label: therapy, value: therapy } : null}
                  />
                  <Select
                    styles={{
                      container: base => ({
                        ...base,
                        flex: 1,
                        marginRight: theme.space[2],
                        marginBottom: theme.space[2],
                      }),
                    }}
                    options={[
                      ...Object.values(Specialisms).map(x => ({
                        value: x,
                        label: x,
                      })),
                      { value: OTHER_VALUE, label: OTHER_VALUE },
                    ]}
                    placeholder="Specialism"
                    isSearchable={false}
                    onChange={input => {
                      // @ts-ignore
                      setSpecialism(input.value);
                    }}
                    value={
                      specialism
                        ? { label: specialism, value: specialism }
                        : null
                    }
                  />
                  <Select
                    styles={{
                      container: base => ({
                        ...base,
                        flex: 1,
                        marginRight: theme.space[2],
                        marginBottom: theme.space[2],
                      }),
                    }}
                    options={Object.values(Genders).map(x => ({
                      value: x,
                      label: x,
                    }))}
                    placeholder="Gender"
                    isSearchable={false}
                    onChange={input => {
                      // @ts-ignore
                      setGender(input.value);
                    }}
                    value={gender ? { label: gender, value: gender } : null}
                  />
                  <Select
                    styles={{
                      container: base => ({
                        ...base,
                        flex: 1,
                        marginRight: theme.space[2],
                        marginBottom: theme.space[2],
                      }),
                    }}
                    options={Object.values(Sexualities).map(x => ({
                      value: x,
                      label: x,
                    }))}
                    placeholder="Sexuality"
                    isSearchable={false}
                    onChange={input => {
                      // @ts-ignore
                      setSexuality(input.value);
                    }}
                    value={
                      sexuality ? { label: sexuality, value: sexuality } : null
                    }
                  />
                  <Select
                    styles={{
                      container: base => ({
                        ...base,
                        flex: 1,
                        marginRight: theme.space[2],
                        marginBottom: theme.space[2],
                      }),
                    }}
                    options={Object.values(Ethnicities).map(x => ({
                      value: x,
                      label: x,
                    }))}
                    placeholder="Ethnicity"
                    isSearchable={false}
                    onChange={input => {
                      // @ts-ignore
                      setEthnicity(input.value);
                    }}
                    value={
                      ethnicity ? { label: ethnicity, value: ethnicity } : null
                    }
                  />
                  <Select
                    styles={{
                      container: base => ({
                        ...base,
                        flex: 1,
                        marginRight: theme.space[2],
                        marginBottom: theme.space[2],
                      }),
                    }}
                    options={pricingBands.map(x => ({
                      value: x,
                      label: `£${x} and below`,
                    }))}
                    placeholder="Max Price"
                    isSearchable={false}
                    onChange={input => {
                      // @ts-ignore
                      setMaxPrice(input.value);
                    }}
                    value={
                      maxPrice
                        ? { label: `£${maxPrice} and below`, value: maxPrice }
                        : null
                    }
                  />
                  <Input
                    isMaterial
                    isDirectoryInput
                    label="Therapist Name"
                    name="therapistName"
                    value={therapistName}
                    onChange={e => setTherapistName(e.target.value)}
                  />
                </Box>
                {(!!maxPrice ||
                  !!ethnicity ||
                  !!sexuality ||
                  !!gender ||
                  !!specialism ||
                  !!therapy) && (
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={11}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pt={1}
                    pb={1}
                    color="white"
                    style={{ textTransform: 'uppercase', cursor: 'pointer' }}
                    onClick={() => {
                      setMaxPrice(null);
                      setEthnicity(null);
                      setSexuality(null);
                      setGender(null);
                      setSpecialism(null);
                      setTherapy(null);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size="lg"
                      color="white"
                    />{' '}
                    Clear all filters
                  </Text>
                )}
              </Box>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        style={{
          backgroundColor: theme.colors.sand,
          paddingTop: theme.space[4],
        }}
      >
        <Container>
          <Row>{therapistsToShow.slice((page - 1) * 9, page * 9)}</Row>
          <Row>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: theme.space[4],
              }}
            >
              <Pagination
                count={Math.ceil(therapistsToShow.length / 9)}
                size="large"
                onChange={(e, number) => {
                  e.preventDefault();
                  setPage(number);
                  if (typeof window !== 'undefined') {
                    window.scrollTo(0, 0);
                  }
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
};

export default DirectoryAuth;
