import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../services/firebase';
import DirectoryAuth from '../screens/auth/DirectoryAuth';
import { Redirect } from '@reach/router';

const DirectoryPage = () => {
  const [user, loading, error] = useAuthState(getFirebaseAuth());

  if (loading) {
    return <></>;
  }

  return user ? <DirectoryAuth /> : <Redirect noThrow to="/" />;
};

export default DirectoryPage;
